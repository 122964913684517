import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DescriptionIcon from "@material-ui/icons/Description";
import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import PageviewIcon from "@material-ui/icons/Pageview";
import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";
import { Alert, AlertTitle } from "@material-ui/lab";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as orderApi from "../../api/orderApi";
import { _handleObjectProperty } from "../../common/convert";
import { getRateFuelAmountRateResults } from "../../common/getRateFuelAmountRateResults";
import * as setActivity from "../../common/setActivity";
import TruckArrow from "../Logo/TruckIconNewSmall.png";
import RateEmail from "./RateEmail";
var _ = require("lodash");

const RateResults = (props) => {
   const [saving, setSaving] = useState(false);
   const [showRateResults, setShowRateResults] = useState(true);
   const [slideDirection, setSlideDirection] = useState("left");
   const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
   const [orderNumber, setOrderNumber] = useState("");
   const [orderId, setOrderId] = useState("");
   const [buttonClicked, setButtonClicked] = useState(false);
   const [downloadingPDF, setDownloadingPDF] = useState(false);
   const [viewingPDF, setViewingPDF] = useState(false);
   const [rateEmailModal, setRateEmailModal] = useState(false);

   const descriptionElementRef = useRef(null);
   useEffect(() => {
      if (open) {
         const { current: descriptionElement } = descriptionElementRef;
         if (descriptionElement !== null) {
            descriptionElement.focus();
         }
      }
   }, [props?.openResults]);
   const useStyles = makeStyles((theme) => ({
      root: {
         width: "100%",
         "& > * + *": {
            marginTop: theme.spacing(2),
         },
         zIndex: "9999",
         fontWeight: "bold",
      },
      filledWarning: {
         backgroundColor: "#002D72",
         color: "#ffffff",
      },
      containerStyle: {
         [theme.breakpoints.up("sm")]: {
            width: "100%",
         },
         backgroundColor: "#F2F2F2",
         zIndex: "2",
         width: "100%",
         paddingTop: ".5rem",
         paddingBottom: ".5rem",
         borderRadius: theme.shape.borderRadius,
         height: "10vh",
      },
      containerStyleConfirmation: {
         [theme.breakpoints.up("sm")]: {
            width: "100%",
         },
         backgroundColor: "#F2F2F2",
         zIndex: "2",
         width: "100%",
         borderRadius: theme.shape.borderRadius,
      },
      title: {
         flexGrow: 1,
         textAlign: "left",
         color: "#6F6F6F",
      },
      button: {
         color: "white",
         backgroundColor: "#002D72",
      },
      finalizeButton: {
         color: "white",
         backgroundColor: "#4caf50",
         "&:hover": {
            backgroundColor: "#357a38",
         },
      },
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      buttonRemove: {
         width: "100%",
         borderTopLeftRadius: 0,
         borderBottomLeftRadius: 0,
      },
      labelRemove: {
         flexDirection: "column",
         fontSize: "11px",
      },
      icon: {
         fontSize: "24px",
         marginBottom: theme.spacing.unit,
      },
      backdrop: {
         zIndex: theme.zIndex.drawer + 1,
         color: "#ffffff",
      },
      buttonProgress: {
         fontSize: "24px",
         marginBottom: theme.spacing.unit,
         color: green[500],
      },
      buttonProgressConfirmation: {
         fontSize: "24px",
         color: green[500],
      },
      checkBoxIcon: {
         marginTop: theme.spacing.unit,
         color: green[500],
         fontSize: "175px",
      },
      disclaimerColumn: {
         paddingLeft: "1rem",
         display: "flex",
         justifyContent: "flex-end",
         backgroundColor: "#F2F2F2",
         borderRadius: theme.shape.borderRadius,
         marginBottom: "2rem",
      },
   }));

   const classes = useStyles();

   const calcCPGrate = (base_rate, tolls, bobtail) => {
      return +(base_rate ? base_rate : 0) + +(tolls ? tolls : 0) + +(bobtail ? bobtail : 0);
   };

   function getRateFuelFormat(rate, rateInfo) {
      rate.selectedRateInfo = rateInfo;

      if (!rate.fuelTariff) {
         return "N/A";
      }

      var cpm = 0;
      var ppr = 0;

      if (rate.fuelTariff) {
         if (rate.fuelTariff.overUnder) {
            cpm = rate.fuelTariff.overUnder.cpm;
            ppr = rate.fuelTariff.overUnder.ppr;
         } else {
            //get current tariff info if no over under exists
            cpm = rate.fuelTariff.tariffInfo.cpm;
            ppr = rate.fuelTariff.tariffInfo.ppr;
         }

         if (cpm > 0.0) {
            return "$" + parseFloat(cpm).toFixed(2) + "/mi.";
         } else {
            //PPR
            return parseFloat(ppr).toFixed(2) + "%";
         }
      } else {
         //no tariff found in either default or customer
         return 0;
      }
   }

   function generateQuote() {
      if (props.rates.length === 0) {
         props.openAlertMessage(
            <div>
               No current rate found. Please reach out to the Pricing Team at{" "}
               <Link href="mailto:Quote@Containerport.com">Quote@Containerport.com</Link> for an updated rate.
            </div>,
            "warning",
         );
         return;
      }

      setSaving(true);

      var items = [];

      _.forEach(props.rates, async (rate) => {
         let item = {
            rate: {
               customer_id: props.user.customers_ids[0],
               delete_date: null,
               rateInfo: {
                  shipment_type: rate.selectedRateInfo.shipment_type,
                  _id: rate.selectedRateInfo._id,
                  miles: rate.selectedRateInfo.miles,
                  tolls: rate.selectedRateInfo.tolls,
                  cost: rate.selectedRateInfo.cost,
                  base_rate: rate.selectedRateInfo.base_rate,
                  bobtail: rate.selectedRateInfo.bobtail,
                  lane_type: rate.selectedRateInfo.lane_type,
                  service: rate.selectedRateInfo.service,
                  add_date: rate.selectedRateInfo.add_date,
                  startDate: rate.selectedRateInfo.startDate,
                  endDate: rate.selectedRateInfo.endDate,
               },
               add_date: rate.add_date,
               _id: rate._id,
               terminal_id: rate.terminal_id,
               destination: {
                  city: rate.destination.city,
                  state: rate.destination.state,
                  zip: rate.destination.zip,
               },
               active: rate.active,
            },
            customer_id: props.user.customers_ids[0],
            terminal_id: rate.terminal_id,
            fuelTariff: {
               tariffInfo: {
                  ppr: rate.fuelTariff.tariffInfo.ppr,
                  cpm: rate.fuelTariff.tariffInfo.cpm,
               },
               overUnder: rate.fuelTariff.overUnder
                  ? {
                       ppr: rate.fuelTariff.overUnder.ppr,
                       cpm: rate.fuelTariff.overUnder.cpm,
                    }
                  : null,
            },
            accessorialTariff: {
               _id: rate.accessorialTariff._id,
               accessorialGuide: rate.accessorialTariff.accessorialGuide,
            },
            overrides: {},
         };

         items = items.concat(item);
      });

      let rateTotal = getRateAmount(items);

      //Match the order schema.
      var saveNewOrder = {
         _id: null,
         add_date: null,
         user_id: props.user._id,
         items: items,
         rate_total: +rateTotal,
         orderNumber: null,
         status: "COMPLETED",
      };

      //Call checkout API.
      orderApi.checkout(saveNewOrder).then((resp) => {
         //Add to log activity.
         try {
            var activity = {
               module: "QUOTE",
               record_id: resp.order,
               event: "Added new/edited quote: " + resp.order.orderNumber,
               user_id: props.user._id,
            };

            setActivity.setActivity("TRTSITE", "USERLOG", activity);

            setOrderNumber(resp.order.orderNumber);
            setOrderId(resp.order._id);
            setSlideDirection("right");
            setShowRateResults(false);

            setTimeout(function () {
               setShowOrderConfirmation(true);
               setSaving(false);
            }, 500);
         } catch (err) {
            props.openAlertMessage("Unable to save quote. Please contact your administrator for assistance.", "error");
            setSaving(false);
            return;
         }
      });
   }

   function viewPDF() {
      setButtonClicked(true);
      setViewingPDF(true);

      //get pdf and open new window to view it
      orderApi
         .getRateSheetPortal(orderId, null)
         .then((response) => {
            if (response && response.ok === true) {
               return response.arrayBuffer().then((buffer) => {
                  const blob = new Blob([buffer], { type: "application/pdf" });
                  window.open(URL.createObjectURL(blob));
                  setButtonClicked(false);
                  setViewingPDF(false);
               });
            } else {
               props.openAlertMessage(
                  "Error loading PDF rate sheet. Please contact your administrator for assistance.",
                  "error",
               );
               setButtonClicked(false);
               setViewingPDF(false);
            }
         })
         .catch((err) => {
            console.error(err);
            props.openAlertMessage(
               "Error loading PDF rate sheet. Please contact your administrator for assistance.",
               "error",
            );
            setButtonClicked(false);
            setViewingPDF(false);
         });
   }

   function downloadPDF() {
      setButtonClicked(true);
      setDownloadingPDF(true);

      //get pdf and open new window to view it
      orderApi
         .getRateSheetPortal(orderId, null)
         .then((response) => {
            if (response && response.ok === true) {
               return response.arrayBuffer().then((buffer) => {
                  const blob = new Blob([buffer], { type: "application/pdf" });
                  saveAs(blob, `${orderNumber}.pdf`);
                  setButtonClicked(false);
                  setDownloadingPDF(false);
               });
            } else {
               props.openAlertMessage(
                  "Error loading PDF rate sheet. Please contact your administrator for assistance.",
                  "error",
               );
               setButtonClicked(false);
               setDownloadingPDF(false);
            }
         })
         .catch((err) => {
            props.openAlertMessage(
               "Error loading PDF rate sheet. Please contact your administrator for assistance.",
               "error",
            );
            setButtonClicked(false);
            setDownloadingPDF(false);
         });
   }

   function getRateAmount(items) {
      try {
         let totalRate = 0;

         for (let item in items) {
            totalRate += +parseFloat(
               items[item].rate.rateInfo
                  ? items[item].rate.rateInfo.base_rate
                     ? items[item].rate.rateInfo.base_rate
                     : 0
                  : 0,
            );
         }

         return totalRate;
      } catch (err) {
         //handleError(err);
      }
   }

   function resetSearch() {
      props.setOpenResults(false);
      props.setSearchResults([]);

      var newSearchObj = {
         selectedTerminal: null,
         selectedCustomer: "",
         selectedDestination: {
            city: null,
            state: null,
            zip: null,
         },
      };

      props.setSearch([newSearchObj]);
   }

   function removeRate(index) {
      var newRate = [...props.rates];

      newRate.splice(index, 1);

      if (newRate.length === 0) {
         props.openAlertMessage(
            <div>
               No current rate found. Please reach out to the Pricing Team at{" "}
               <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                  Quote@Containerport.com
               </Link>{" "}
               for an updated rate.
            </div>,
            "warning",
         );
      }

      if (_.filter(newRate, (x) => x.customer_id !== "~NOT FOUND~").length === 0) {
         props.openAlertMessage(
            <div>
               No current rate found. Please reach out to the Pricing Team at{" "}
               <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                  Quote@Containerport.com
               </Link>{" "}
               for an updated rate.
            </div>,
            "warning",
         );
      }

      props.setSearchResults(newRate);
   }

   function removeRateGroup(index) {
      var newRate = [...props.rates];
      let currentIndex = index;

      newRate.splice(index, 1);

      while (
         _handleObjectProperty(newRate[currentIndex], "groupTerminal") === true &&
         _handleObjectProperty(newRate[currentIndex], "isFirstRecord") === false
      ) {
         newRate.splice(currentIndex, 1);
         currentIndex = currentIndex++;
      }

      if (newRate.length === 0) {
         props.openAlertMessage(
            <div>
               No current rate found. Please reach out to the Pricing Team at{" "}
               <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                  Quote@Containerport.com
               </Link>{" "}
               for an updated rate.
            </div>,
            "warning",
         );
      }

      if (_.filter(newRate, (x) => x.customer_id !== "~NOT FOUND~").length === 0) {
         props.openAlertMessage(
            <div>
               No current rate found. Please reach out to the Pricing Team at{" "}
               <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                  Quote@Containerport.com
               </Link>{" "}
               for an updated rate.
            </div>,
            "warning",
         );
      }

      props.setSearchResults(newRate);
   }

   function closeRateEmail() {
      setRateEmailModal(!rateEmailModal);
      setButtonClicked(!buttonClicked);
   }

   return (
      <>
         {props.showAlert ? (
            <div className={classes.root}>
               <Alert
                  variant="filled"
                  severity={props.alertType ? props.alertType : "success"}
                  className={classes.filledWarning}
                  onClose={() => props.setOpenResults(false)}
               >
                  <AlertTitle>No Results</AlertTitle>
                  {props.alertMessage}
               </Alert>
            </div>
         ) : (
            <>
               <Slide direction={slideDirection} in={showRateResults} mountOnEnter unmountOnExit timeout={500}>
                  <div>
                     {/* <DialogTitle id="scroll-dialog-title"> */}
                     <Toolbar className={classes.containerStyle}>
                        <Grid container spacing={0} direction="column" alignItems="flex-start" justify="center" xs={4}>
                           <Grid item xs={12}>
                              <Typography variant="h6" className={classes.title} noWrap={true}>
                                 Rate Results
                              </Typography>
                           </Grid>
                        </Grid>
                        <Grid container spacing={0} direction="column" alignItems="center" justify="center" xs={4}>
                           <Grid item xs={12}>
                              <Button
                                 classes={{ root: classes.finalizeButton, label: classes.label }}
                                 variant="contained"
                                 color="primary"
                                 onClick={() => generateQuote()}
                                 disabled={saving}
                              >
                                 {saving ? (
                                    <CircularProgress size={24} className={classes.buttonProgress} />
                                 ) : (
                                    <DescriptionIcon className={classes.icon} />
                                 )}
                                 Finalize Quote
                              </Button>
                           </Grid>
                        </Grid>
                        <Grid container spacing={0} direction="column" alignItems="center" justify="center" xs={4}>
                           <Grid item xs={12}>
                              <Button
                                 classes={{ root: classes.button, label: classes.label }}
                                 variant="contained"
                                 color="primary"
                                 onClick={() => resetSearch()}
                                 disabled={saving}
                              >
                                 <YoutubeSearchedForIcon className={classes.icon} />
                                 Reset Search
                              </Button>
                           </Grid>
                        </Grid>
                        <Grid container spacing={0} direction="column" alignItems="flex-end" justify="flex-end" xs={4}>
                           <Grid item xs={12}>
                              <IconButton
                                 color="secondary"
                                 style={{ float: "right" }}
                                 onClick={() => props.setOpenResults(false)}
                                 disabled={saving}
                              >
                                 <CloseIcon />
                              </IconButton>
                           </Grid>
                        </Grid>
                     </Toolbar>
                     {/* </DialogTitle> */}

                     <DialogContent dividers="true">
                        <DialogContentText
                           id="scroll-dialog-description"
                           ref={descriptionElementRef}
                           tabIndex={-1}
                           style={{
                              minHeight: "615px",
                           }}
                        >
                           <List
                           // style={{ maxHeight: "calc(85vh - 78px)" }}
                           >
                              {props.rates.map((rate, index) => {
                                 return rate.customer_id === "~NOT FOUND~" ? (
                                    <Alert variant="filled" severity="warning" onClose={() => removeRate(index)}>
                                       <AlertTitle>No Results</AlertTitle>
                                       {`No results returned from ${_handleObjectProperty(
                                          props.terminals.find((terminal) => {
                                             return terminal._id === rate.terminal_id;
                                          }),
                                          "name",
                                       )} 
                            to ${rate.destination.city + ", " + rate.destination.state}`}
                                       {props.alertMessage}
                                    </Alert>
                                 ) : (
                                    <Paper
                                       elevation={3}
                                       style={{
                                          textAlign: "-webkit-center",
                                          margin: "1rem",
                                          backgroundColor: index % 2 !== 0 ? "#F2F2F2" : "#FFFFFF",
                                          color: "#6F6F6F",
                                       }}
                                    >
                                       <Grid container item xs={12} direction={"row"}>
                                          <Grid item direction={"column"} xs={11} style={{ padding: "2%" }}>
                                             {/* <Grid
                                          item
                                          container
                                          xs={12}
                                          direction={"row"}
                                          style={{ marginBottom: ".25rem" }}
                                       >
                                          <Grid item xs={9} />
                                          <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-start" }}>
                                              <div>
                                                <b style={{ color: "#4d4b4b" }}>{"Miles: "}</b>
                                                {+rate.rateInfo.miles}
                                             </div> 
                                          </Grid>
                                       </Grid> */}
                                             <Grid item container xs={12} direction={"row"}>
                                                <Grid item xs={6}>
                                                   <Typography noWrap={true}>
                                                      {_handleObjectProperty(
                                                         props.terminals.find((terminal) => {
                                                            return terminal._id === rate.terminal_id;
                                                         }),
                                                         "name",
                                                      )}
                                                   </Typography>
                                                </Grid>
                                                <Grid item xs={1} container display="flex" justify="center">
                                                   <img style={{ width: "85px" }} src={TruckArrow} alt=""></img>
                                                </Grid>
                                                <Grid item xs={5}>
                                                   <Typography noWrap={true}>
                                                      {rate.destination.city + ", " + rate.destination.state}
                                                   </Typography>
                                                </Grid>
                                                {/* <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-start" }}>
                                             <div>
                                                <b style={{ color: "#4d4b4b" }}>{"Rate: "}</b>{" "}
                                                {"$" +
                                                   calcCPGrate(
                                                      rate.rateInfo.base_rate,
                                                      rate.rateInfo.tolls,
                                                      rate.rateInfo.bobtail,
                                                   ).toFixed(2)}
                                             </div>
                                          </Grid> */}
                                             </Grid>
                                             {/* <Grid item container xs={12} direction={"row"}>
                                          <Grid item xs={9} />
                                          <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-start" }}>
                                             <div>
                                                <b style={{ color: "#4d4b4b" }}>{"FSC: "}</b>{" "}
                                                {`$${Number.parseFloat(getRateFuelAmountRateResults(rate)).toFixed(
                                                   2,
                                                )} (${getRateFuelFormat(rate, rate.rateInfo)})`}
                                             </div>
                                          </Grid>
                                       </Grid> */}
                                          </Grid>
                                          {rate.groupTerminal ? (
                                             rate.isFirstRecord ? (
                                                <Grid item container direction={"column"} xs={1} align="center">
                                                   <Grid item xs={12} container display="flex" justify="center">
                                                      <Button
                                                         classes={{
                                                            root: classes.buttonRemove,
                                                            label: classes.labelRemove,
                                                         }}
                                                         variant="contained"
                                                         color="secondary"
                                                         onClick={() => removeRateGroup(index)}
                                                         disabled={saving}
                                                      >
                                                         <DeleteForeverIcon className={classes.icon} />
                                                         Remove Group
                                                      </Button>
                                                   </Grid>
                                                </Grid>
                                             ) : null
                                          ) : (
                                             <Grid item container direction={"column"} xs={1} align="center">
                                                <Grid item xs={12} container display="flex" justify="center">
                                                   <Button
                                                      classes={{
                                                         root: classes.buttonRemove,
                                                         label: classes.labelRemove,
                                                      }}
                                                      variant="contained"
                                                      color="secondary"
                                                      onClick={() => removeRate(index)}
                                                      disabled={saving}
                                                   >
                                                      <DeleteForeverIcon className={classes.icon} />
                                                      Remove
                                                   </Button>
                                                </Grid>
                                             </Grid>
                                          )}
                                       </Grid>
                                    </Paper>
                                 );
                              })}
                           </List>
                        </DialogContentText>
                     </DialogContent>
                  </div>
               </Slide>
               <Slide direction="left" in={showOrderConfirmation} mountOnEnter unmountOnExit timeout={500}>
                  <div>
                     <Toolbar className={classes.containerStyleConfirmation}>
                        <Grid container spacing={0} direction="column" alignItems="flex-start" justify="center" xs={8}>
                           <Grid item xs={12}>
                              <Typography variant="h6" className={classes.title} noWrap={true}>
                                 Quote Confirmation
                              </Typography>
                           </Grid>
                        </Grid>
                        <Grid container spacing={0} direction="column" alignItems="flex-end" justify="flex-end" xs={4}>
                           <Grid item xs={12}>
                              <IconButton
                                 color="secondary"
                                 style={{ float: "right" }}
                                 onClick={() => resetSearch()}
                                 disabled={buttonClicked}
                              >
                                 <CloseIcon />
                              </IconButton>
                           </Grid>
                        </Grid>
                     </Toolbar>

                     <DialogContent dividers="true">
                        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                           <Zoom in={showOrderConfirmation} style={{ transitionDelay: "500ms" }} timeout={500}>
                              <Grid
                                 container
                                 spacing={0}
                                 direction="column"
                                 alignItems="center"
                                 justify="center"
                                 xs={12}
                              >
                                 <CheckCircleIcon className={classes.checkBoxIcon} />
                              </Grid>
                           </Zoom>
                           <Grid container spacing={0} direction="column" alignItems="center" justify="center" xs={12}>
                              <Typography variant="h3" className={classes.title} noWrap={true}>
                                 {`Quote#: ${orderNumber}`}
                              </Typography>
                           </Grid>
                           {/* <List
                              sx={{
                                 width: "100%",
                                 maxWidth: 360,
                                 bgcolor: "background.paper",
                                 position: "relative",
                                 overflow: "auto",
                                 maxHeight: 300,
                                 "& ul": { padding: 0 },
                              }}
                              subheader={<li />}
                           >
                              {[0, 1, 2, 3, 4].map((sectionId) => (
                                 <li key={`section-${sectionId}`}>
                                    <ul>
                                       <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                                       {[0, 1, 2].map((item) => (
                                          <ListItem key={`item-${sectionId}-${item}`}>
                                             <ListItemText primary={`Item ${item}`} />
                                          </ListItem>
                                       ))}
                                    </ul>
                                 </li>
                              ))}
                           </List> */}
                           <List
                              //style={{ maxHeight: "calc(85vh - 100px)" }}
                              sx={{
                                 // width: "100%",
                                 // maxWidth: 360,
                                 //bgcolor: "background.paper",
                                 position: "relative",
                                 overflow: "auto",
                                 maxHeight: 5,
                                 //"& ul": { padding: 0 },
                              }}
                           >
                              {props.rates.map((rate, index) => {
                                 return rate.customer_id === "~NOT FOUND~" ? (
                                    <Alert variant="filled" severity="warning" onClose={() => removeRate(index)}>
                                       <AlertTitle>No Results</AlertTitle>
                                       {`No results returned from ${_handleObjectProperty(
                                          props.terminals.find((terminal) => {
                                             return terminal._id === rate.terminal_id;
                                          }),
                                          "name",
                                       )} 
                            to ${rate.destination.city + ", " + rate.destination.state}`}
                                       {props.alertMessage}
                                    </Alert>
                                 ) : (
                                    <Paper
                                       elevation={3}
                                       style={{
                                          textAlign: "-webkit-center",
                                          margin: "1rem",
                                          backgroundColor: index % 2 !== 0 ? "#F2F2F2" : "#FFFFFF",
                                          color: "#6F6F6F",
                                       }}
                                    >
                                       <Grid container item xs={12} direction={"row"}>
                                          <Grid item direction={"column"} xs={12} style={{ padding: "2%" }}>
                                             <Grid
                                                item
                                                container
                                                xs={12}
                                                direction={"row"}
                                                style={{ marginBottom: ".25rem" }}
                                             >
                                                <Grid item xs={9} />
                                                <Grid
                                                   item
                                                   xs={3}
                                                   style={{ display: "flex", justifyContent: "flex-start" }}
                                                >
                                                   <div>
                                                      <b style={{ color: "#4d4b4b" }}>{"Miles: "}</b>
                                                      {+rate.rateInfo.miles}
                                                   </div>
                                                </Grid>
                                             </Grid>
                                             <Grid item container xs={12} direction={"row"}>
                                                <Grid item xs={4}>
                                                   <Typography noWrap={true}>
                                                      {_handleObjectProperty(
                                                         props.terminals.find((terminal) => {
                                                            return terminal._id === rate.terminal_id;
                                                         }),
                                                         "name",
                                                      )}
                                                   </Typography>
                                                </Grid>
                                                <Grid item xs={1} container display="flex" justify="center">
                                                   <img style={{ width: "85px" }} src={TruckArrow} alt=""></img>
                                                </Grid>
                                                <Grid item xs={4}>
                                                   <Typography noWrap={true}>
                                                      {rate.destination.city + ", " + rate.destination.state}
                                                   </Typography>
                                                </Grid>
                                                <Grid
                                                   item
                                                   xs={3}
                                                   style={{ display: "flex", justifyContent: "flex-start" }}
                                                >
                                                   <div>
                                                      <b style={{ color: "#4d4b4b" }}>{"Rate: "}</b>{" "}
                                                      {"$" +
                                                         calcCPGrate(
                                                            rate.rateInfo.base_rate,
                                                            rate.rateInfo.tolls,
                                                            rate.rateInfo.bobtail,
                                                         ).toFixed(2)}
                                                   </div>
                                                </Grid>
                                             </Grid>
                                             <Grid item container xs={12} direction={"row"}>
                                                <Grid item xs={9} />
                                                <Grid
                                                   item
                                                   xs={3}
                                                   style={{ display: "flex", justifyContent: "flex-start" }}
                                                >
                                                   <div>
                                                      <b style={{ color: "#4d4b4b" }}>{"FSC: "}</b>{" "}
                                                      {`$${Number.parseFloat(
                                                         getRateFuelAmountRateResults(rate),
                                                      ).toFixed(2)} (${getRateFuelFormat(rate, rate.rateInfo)})`}
                                                   </div>
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </Paper>
                                 );
                              })}
                           </List>

                           <Grid container xs={12} direction={"row"} style={{ marginTop: "1.5rem", height: "3rem" }}>
                              <Zoom in={showOrderConfirmation} style={{ transitionDelay: "500ms" }} timeout={500}>
                                 <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="flex-end"
                                    justify="flex-end"
                                    xs={3}
                                 >
                                    <Button
                                       className={classes.button}
                                       style={{ width: "50%", height: "100%" }}
                                       variant="contained"
                                       color="primary"
                                       onClick={() => {
                                          setRateEmailModal(true);
                                          setButtonClicked(true);
                                       }}
                                       disabled={buttonClicked}
                                    >
                                       <EmailIcon />
                                    </Button>
                                 </Grid>
                              </Zoom>
                              <Zoom in={showOrderConfirmation} style={{ transitionDelay: "500ms" }} timeout={500}>
                                 <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    xs={9}
                                    style={{
                                       paddingLeft: "1rem",
                                       paddingTop: ".5rem",
                                       display: "flex",
                                       justifyContent: "flex-start",
                                       height: "3rem",
                                    }}
                                 >
                                    <Typography variant="h5" className={classes.title} noWrap={true}>
                                       Email Quote Sheet
                                    </Typography>
                                 </Grid>
                              </Zoom>
                           </Grid>
                           <Grid container xs={12} direction={"row"} style={{ marginTop: "1.5rem", height: "3rem" }}>
                              <Zoom in={showOrderConfirmation} style={{ transitionDelay: "750ms" }} timeout={500}>
                                 <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="flex-end"
                                    justify="flex-end"
                                    xs={3}
                                 >
                                    <Button
                                       className={classes.button}
                                       style={{ width: "50%", height: "100%" }}
                                       variant="contained"
                                       color="primary"
                                       onClick={() => downloadPDF()}
                                       disabled={buttonClicked}
                                    >
                                       {downloadingPDF ? (
                                          <CircularProgress size={24} className={classes.buttonProgressConfirmation} />
                                       ) : (
                                          <GetAppIcon />
                                       )}
                                    </Button>
                                 </Grid>
                              </Zoom>
                              <Zoom in={showOrderConfirmation} style={{ transitionDelay: "750ms" }} timeout={500}>
                                 <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    xs={9}
                                    style={{
                                       paddingLeft: "1rem",
                                       paddingTop: ".5rem",
                                       display: "flex",
                                       justifyContent: "flex-start",
                                       height: "3rem",
                                    }}
                                 >
                                    <Typography variant="h5" className={classes.title} noWrap={true}>
                                       Download Quote Sheet
                                    </Typography>
                                 </Grid>
                              </Zoom>
                           </Grid>
                           <Grid container xs={12} direction={"row"} style={{ marginTop: "1.5rem", height: "3rem" }}>
                              <Zoom in={showOrderConfirmation} style={{ transitionDelay: "1000ms" }} timeout={500}>
                                 <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="flex-end"
                                    justify="flex-end"
                                    xs={3}
                                 >
                                    <Button
                                       className={classes.button}
                                       style={{ width: "50%", height: "100%" }}
                                       variant="contained"
                                       color="primary"
                                       onClick={() => viewPDF()}
                                       disabled={buttonClicked}
                                    >
                                       {viewingPDF ? (
                                          <CircularProgress size={24} className={classes.buttonProgressConfirmation} />
                                       ) : (
                                          <PageviewIcon />
                                       )}
                                    </Button>
                                 </Grid>
                              </Zoom>
                              <Zoom in={showOrderConfirmation} style={{ transitionDelay: "1000ms" }} timeout={500}>
                                 <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    xs={9}
                                    style={{
                                       paddingLeft: "1rem",
                                       paddingTop: ".5rem",
                                       display: "flex",
                                       justifyContent: "flex-start",
                                       height: "3rem",
                                    }}
                                 >
                                    <Typography variant="h5" className={classes.title} noWrap={true}>
                                       View Quote Sheet
                                    </Typography>
                                 </Grid>
                              </Zoom>
                           </Grid>
                        </DialogContentText>
                     </DialogContent>
                     <DialogActions>
                        <Zoom in={showOrderConfirmation} style={{ transitionDelay: "1000ms" }} timeout={500}>
                           <Grid
                              container
                              xs={12}
                              direction={"row"}
                              //style={{ position: "absolute", width: "60%", bottom: 0, marginBottom: "5vh" }}
                           >
                              <Grid
                                 container
                                 spacing={0}
                                 direction="column"
                                 xs={5}
                                 align="left"
                                 className={classes.disclaimerColumn}
                              >
                                 <Typography
                                    className={classes.title}
                                    style={{ fontSize: "11px", textDecoration: "underline" }}
                                    noWrap={false}
                                 >
                                    This rate quote is valid for 60 Days.
                                 </Typography>
                                 <Typography className={classes.title} style={{ fontSize: "11px" }} noWrap={false}>
                                    RATE QUOTE IS NOT A CONFIRMATION THAT CPG WILL HAUL YOUR SHIPMENT.
                                 </Typography>
                              </Grid>
                              <Grid
                                 container
                                 spacing={0}
                                 direction="column"
                                 alignItems="flex-end"
                                 justify="flex-end"
                                 xs={7}
                              >
                                 <Grid item xs={12} style={{ marginRight: "1rem", marginBottom: "1rem" }}>
                                    <Button
                                       classes={{ root: classes.button, label: classes.label }}
                                       variant="contained"
                                       color="primary"
                                       onClick={() => resetSearch()}
                                       disabled={buttonClicked}
                                    >
                                       <YoutubeSearchedForIcon className={classes.icon} />
                                       Generate New Quote
                                    </Button>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Zoom>
                     </DialogActions>
                  </div>
               </Slide>

               <RateEmail
                  open={rateEmailModal}
                  handleClose={() => closeRateEmail()}
                  orderNumber={orderNumber}
                  orderId={orderId}
                  userId={props.user._id}
                  userEmail={props.user.email}
               />
            </>
         )}
      </>
   );
};

function mapStateToProps(state) {
   return {
      customer: state.customer !== null ? state.customer.customer : {},
      user: state.user.currentUser,
      terminals: state.terminal.terminals,
   };
}

RateResults.propTypes = {
   showAlert: PropTypes.bool.isRequired,
   alertType: PropTypes.string.isRequired,
   alertMessage: PropTypes.string.isRequired,
   customer: PropTypes.object.isRequired,
   user: PropTypes.object.isRequired,
   terminals: PropTypes.array.isRequired,
   rates: PropTypes.array.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
   setOpenResults: PropTypes.func.isRequired,
   setSearchResults: PropTypes.func.isRequired,
   setSearch: PropTypes.func.isRequired,
};

RateResults.defaultProps = {
   showAlert: false,
   alertType: "",
   alertMessage: "",
   customer: {},
   user: {},
   terminals: [],
   rates: [],
   openAlertMessage: () => {
      return;
   },
   setOpenResults: () => {
      return;
   },
   setSearchResults: () => {
      return;
   },
   setSearch: () => {
      return;
   },
};

export default connect(mapStateToProps)(RateResults);
