import { Button, Grid, Paper, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import * as rateApi from "../../api/rateApi";
import { stateList } from "../../common/stateListAbbreviation";
import * as terminalActions from "../../redux/actions/terminalActions";
import "../../styles/RateSearch.css";
import "../../styles/truck.css";
import PlaceSearch from "../Google/PlaceSearch";
import { LayoutHeader } from "../Layout/Layout";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";
import TruckArrow from "../Logo/TruckIconNewLarge.png";
import RateResults from "./RateResults";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
   mainControl: {
      [theme.breakpoints.up("sm")]: {
         width: `calc(100% - ${drawerWidth}px)`,
         marginLeft: drawerWidth,
      },
      height: "100%",
      paddingTop: "95px",
   },
   backdrop: {
      zIndex: 4,
      color: "#ffffff",
   },
   buttonRemove: {
      width: "130px",
   },
   labelRemove: {
      flexDirection: "column",
      fontSize: "9px",
   },
   buttonReset: {
      width: "100%",
   },
   labelReset: {
      flexDirection: "column",
      fontSize: "9px",
   },
   buttonSearch: {
      width: "130px",
      float: "right",
   },
   labelSearch: {
      flexDirection: "column",
      fontSize: "9px",
   },
   buttonAdd: {
      width: "150px",
   },
   labelAdd: {
      flexDirection: "column",
      fontSize: "9px",
   },
}));

const RateSearch = (props) => {
   let [loading, setLoading] = useState(false);
   let [searchResults, setSearchResults] = useState([]);
   let [selectedCustomer, setSelectedCustomer] = useState(null);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");
   const [openResults, setOpenResults] = useState(false);
   const [loadingQuote, setLoadingQuote] = React.useState(false);

   const handleError = useErrorHandler();
   let [search, setSearch] = useState([
      {
         selectedTerminal: null,
         selectedCustomer: "",
         selectedDestination: {
            city: null,
            state: null,
            zip: null,
         },
      },
   ]);

   useEffect(() => {
      if (!props.terminals.length) {
         props.getTerminals();
      }
   }, [props.terminals]);

   function onDestinationChange(event, values, index) {
      try {
         //need to check to see how many array items were returned in google api results
         var destination = {
            city: null,
            state: null,
            zip: null,
            place_id: null,
         };

         if (values) {
            //input defaults
            destination.city = values.terms[0].value;

            if (
               values.terms[1].value.length > 2 &&
               values.terms[1].value.toUpperCase() !== "USA" &&
               values.terms[1].value.toUpperCase() !== "US"
            ) {
               //See if terms[1] is state or space in city name.
               let state = _.filter(stateList, (x) => x.name.toUpperCase() === values.terms[1].value.toUpperCase());
               let abbreviation = _.filter(
                  stateList,
                  (x) => x.abbreviation.toUpperCase() === values.terms[1].value.toUpperCase(),
               );

               if (state.length > 0 || abbreviation.length > 0) {
                  if (state.length > 0) {
                     destination.state = state[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  } else {
                     destination.state = abbreviation[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  }
               } else {
                  destination.city += " " + values.terms[1].value;

                  if (values.terms[2].value.length > 2) {
                     destination.city += " " + values.terms[2].value;

                     destination.state = values.terms[3].value;

                     if (values.terms.length >= 5) {
                        if (
                           values.terms[4].value.toUpperCase() !== "USA" &&
                           values.terms[4].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[4].value;
                        }
                     }
                  } else {
                     destination.state = values.terms[2].value;

                     if (values.terms.length >= 5) {
                        destination.zip = values.terms[3].value;
                     }
                  }
               }
            } else {
               if (values.terms[1].value.toUpperCase() !== "USA" && values.terms[1].value.toUpperCase() !== "US") {
                  destination.state = values.terms[1].value;
               }

               //if searched by zip, then it will appear with 4 items in array, last one always being country
               if (values.terms.length >= 4) {
                  destination.zip = values.terms[2].value;
               }
            }

            destination.place_id = values.place_id;
         }

         var newSearch = _.cloneDeep(search);
         newSearch[index].selectedDestination = destination;

         setSearch(newSearch);
      } catch (err) {
         handleError(err);
      }
   }

   function onTerminalChange(event, values, index) {
      var newSearch = _.cloneDeep(search);
      newSearch[index].selectedTerminal = values;

      setSearch(newSearch);
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   ///////////search controls///////////////////////////////////
   function onSearchClicked() {
      try {
         //Reset alert
         setShowAlert(false);
         setAlertMessage("");

         //shows slide forsearch results
         setOpenResults(true);

         //shows loading progress bar
         setLoading(true);

         //loop through search queries to create properly formatted searchquery object for the api
         var newMultiSearchResults = [];

         search.forEach((query) => {
            if (query.selectedTerminal && query.selectedDestination.city && query.selectedDestination.state) {
               var newSearch = new rateApi.SearchQuery(
                  props.user.customers_ids[0],
                  query.selectedTerminal._id,
                  query.selectedDestination,
                  true,
               );
               newMultiSearchResults.push(newSearch);
            }
         });

         if (!newMultiSearchResults) {
            openAlertMessage(
               <div>
                  No current rate found. Please reach out to the Pricing Team at{" "}
                  <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                     Quote@Containerport.com
                  </Link>{" "}
                  for an updated rate.
               </div>,
               "warning",
            );
            setLoading(false);
            return false;
         }

         if (newMultiSearchResults.length === 0) {
            openAlertMessage(
               <div>
                  No current rate found. Please reach out to the Pricing Team at{" "}
                  <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                     Quote@Containerport.com
                  </Link>{" "}
                  for an updated rate.
               </div>,
               "warning",
            );
            setLoading(false);
            return false;
         }

         newMultiSearchResults = _.uniqBy(newMultiSearchResults, function (element) {
            return [element.terminalID, element.destination.city, element.destination.state, true].join();
         });

         rateApi
            .searchRatesPortal(newMultiSearchResults)
            .then((resp) => {
               if (resp.rates.length === 0) {
                  openAlertMessage(
                     <div>
                        No current rate found. Please reach out to the Pricing Team at{" "}
                        <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                           Quote@Containerport.com
                        </Link>{" "}
                        for an updated rate.
                     </div>,
                     "warning",
                  );
                  setLoading(false);
                  return false;
               }

               resp.rates = _.filter(
                  resp.rates,
                  (x) => x?.rateInfo?.base_rate + x?.rateInfo?.tolls + x?.rateInfo?.bobtail > 0,
               );

               if (resp.rates.length === 0) {
                  openAlertMessage(
                     <div>
                        Rate not available, please reach out to{" "}
                        <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                           Quote@Containerport.com
                        </Link>
                        .
                     </div>,
                     "warning",
                  );
                  setLoading(false);
                  return false;
               }

               if (_.filter(resp.rates, (x) => x._id !== null && x.customer_id !== "~NOT FOUND~").length === 0) {
                  openAlertMessage(
                     <div>
                        No current rate found. Please reach out to the Pricing Team at{" "}
                        <Link style={{ color: "white" }} href="mailto:Quote@Containerport.com">
                           Quote@Containerport.com
                        </Link>{" "}
                        for an updated rate.
                     </div>,
                     "warning",
                  );
                  setLoading(false);
                  return false;
               }

               //Next set full search results.
               setSearchResults(resp.rates);
               setLoading(false);
               return true;
            })
            .catch((err) => {
               openAlertMessage(
                  `Search Failed: ${err.message}. Please contact your administrator for assistance.`,
                  "error",
               );
               setLoading(false);
               return false;
            });
      } catch (err) {
         handleError(err);
      }
   }

   function deleteSearch(index) {
      var newSearch = _.cloneDeep(search);
      newSearch.splice(index, 1);

      setSearch(newSearch);
   }

   function resetSearch() {
      var newSearchObj = {
         selectedTerminal: null,
         selectedCustomer: "",
         selectedDestination: {
            city: null,
            state: null,
            zip: null,
         },
      };

      setSearch([newSearchObj]);
      setSearchResults([]);
   }

   function additionalSearch() {
      var newSearch = _.cloneDeep(search);

      var newSearchObj = {
         selectedTerminal: null,
         selectedCustomer: "",
         selectedDestination: {
            city: null,
            state: null,
            zip: null,
         },
      };

      newSearch.push(newSearchObj);
      setSearch(newSearch);
   }

   const classes = useStyles();

   return (
      <>
         <div style={{ position: "sticky", zIndex: "4" }}>
            <LayoutHeader resetSearch={resetSearch} />
         </div>
         <Paper
            elevation={3}
            style={{
               height: "calc(~95vh - 128px)",
               maxHeight: "calc(~95vh - 128px)",
               overflow: "auto",
               zIndex: 1,
               width: "calc(100% - ${drawerWidth}px)",
               marginLeft: "240px",
               marginBottom: "5vh",
            }}
         >
            <Grid container direction={"column"} xs={12}>
               {search.map((srch, index) => {
                  return (
                     <div key={`div-${index}`}>
                        <Paper elevation={3} style={{ marginTop: index === 0 ? null : "1.5rem" }}>
                           <Grid container xs={12} direction={"row"} style={{ padding: "2%", paddingLeft: "5.50%" }}>
                              <Grid item xs={4} style={{ alignSelf: "center" }}>
                                 <Autocomplete
                                    id="combo-box-terminal"
                                    options={_.filter(props.terminals, (x) => x.active === true)}
                                    getOptionSelected={(option, value) => option.code === value.code}
                                    getOptionLabel={(option) => {
                                       return option.code + " | " + option.name;
                                    }}
                                    onChange={(e, values) => onTerminalChange(e, values, index)}
                                    value={srch.selectedTerminal}
                                    size="small"
                                    renderInput={(params) => (
                                       <TextField {...params} label="Select Terminal.." variant="outlined" />
                                    )}
                                 />
                              </Grid>
                              <Grid item xs={2}>
                                 <div style={{ textAlign: "center" }}>
                                    <img style={{ width: "150px", marginTop: "0.50rem" }} src={TruckArrow} alt=""></img>
                                 </div>
                              </Grid>
                              <Grid item xs={4} style={{ alignSelf: "center" }}>
                                 <PlaceSearch
                                    onChange={(e, values) => onDestinationChange(e, values, index)}
                                    destination={
                                       (srch.selectedDestination.city, srch.selectedDestination.state)
                                          ? srch.selectedDestination.city + ", " + srch.selectedDestination.state
                                          : null
                                    }
                                 />
                              </Grid>
                              <Grid item xs={2} style={{ alignSelf: "center" }}>
                                 <Button
                                    classes={{ root: classes.buttonRemove, label: classes.labelRemove }}
                                    style={{ float: "right" }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => deleteSearch(index)}
                                 >
                                    <DeleteForeverIcon className={classes.icon} />
                                    Remove
                                 </Button>
                              </Grid>
                           </Grid>
                        </Paper>
                     </div>
                  );
               })}
               <Grid
                  container
                  xs={12}
                  direction={"row"}
                  style={{
                     padding: "1%",
                     textAlignLast: "center",
                     marginTop: "2.5rem",
                     textAlign: "right",
                     paddingLeft: "5%",
                  }}
               >
                  <Grid item xs={10}>
                     <Button
                        classes={{ root: classes.buttonAdd, label: classes.labelAdd }}
                        variant="contained"
                        color="primary"
                        onClick={() => additionalSearch()}
                        disabled={loading}
                     >
                        <AddIcon className={classes.icon} />
                        Add Search
                     </Button>
                  </Grid>
                  <Grid item xs={2} style={{ marginLeft: "-1rem" }}>
                     <Button
                        classes={{ root: classes.buttonSearch, label: classes.labelSearch }}
                        variant="contained"
                        color="primary"
                        onClick={() => onSearchClicked(true)}
                        disabled={loading}
                     >
                        <SearchRoundedIcon className={classes.icon} />
                        Search
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            {/* <Backdrop className={classes.backdrop} open={openResults}> */}

            <Dialog
               open={openResults}
               // onClose={handleClose}
               scroll="paper"
               aria-labelledby="scroll-dialog-title"
               aria-describedby="scroll-dialog-description"
               fullWidth={true}
               maxWidth="lg"
               // fullScreen
            >
               {loading ? (
                  <div>
                     <div className="loader-wrapper" style={{ position: "inherit" }}>
                        <div className="truck-wrapper-fullscreen" style={{ marginLeft: "5%", marginTop: "5%" }}>
                           <div className="truck">
                              <div className="truck-container">
                                 <img
                                    style={{ width: "65px", paddingLeft: "10px", paddingTop: "15px" }}
                                    src={containerLogo}
                                    alt=""
                                 />
                              </div>
                              <div className="glases"></div>
                              <div className="bonet"></div>
                              <div className="base"></div>
                              <div className="base-aux"></div>
                              <div className="wheel-back"></div>
                              <div className="wheel-front"></div>
                              <div className="smoke"></div>
                           </div>
                        </div>
                     </div>
                  </div>
               ) : (
                  <RateResults
                     rates={searchResults}
                     onSearchClicked={onSearchClicked}
                     selectedCustomer={selectedCustomer}
                     setSearchResults={setSearchResults}
                     showAlert={showAlert}
                     alertType={alertType}
                     closeAlertMessage={closeAlertMessage}
                     alertMessage={alertMessage}
                     setOpenResults={setOpenResults}
                     setLoadingQuote={setLoadingQuote}
                     setSearch={setSearch}
                     openAlertMessage={openAlertMessage}
                     openResults={openResults}
                  />
               )}
            </Dialog>
         </Paper>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getTerminals: () => dispatch(terminalActions.getTerminals()),
   };
}

RateSearch.propTypes = {
   user: PropTypes.object.isRequired,
   terminals: PropTypes.array.isRequired,
   getTerminals: PropTypes.func.isRequired,
};

RateSearch.defaultProps = {
   user: {},
   terminals: [],
   getTerminals: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(RateSearch);
